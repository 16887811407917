import { type ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { Button, type ButtonProps } from "ui";

/**
 * A button to submit a form
 */
export function SubmitButton({
  children = "Submit",
  appearance = "primary",
  ...passThrough
}: Omit<
  ButtonProps,
  "isLoading" | "isMenu" | "isSelected" | "isOpen"
>): ReactElement {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <Button
      {...passThrough}
      appearance={appearance}
      isLoading={isSubmitting}
      type="submit"
    >
      {children}
    </Button>
  );
}
